import React from 'react';

interface HtmlImgProps extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src' | 'alt'> {}

interface ImageProps extends HtmlImgProps {
  src: string;
  type: string;
  alt: string;
  fallback?: string;
}

const Image: React.FC<ImageProps> = ({ src, type, alt, fallback = src, ...delegated }) => (
  <picture>
    <source srcSet={`${src}, ${fallback}`} type={type} />
    <img src={src} alt={alt} {...delegated} />
  </picture>
);

export default Image;
