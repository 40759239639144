import React, { InputHTMLAttributes } from 'react';

export const InputToggle: React.FC<InputHTMLAttributes<HTMLInputElement>> = ({ ...props }) => {
  return (
    <label className="rounded-full transition-all hover:opacity-80">
      <input type="checkbox" {...props} className="peer hidden" />
      <div className={`flex h-[22px] w-11 cursor-pointer rounded-[30px] bg-content-button-ghost-disable p-[2px] transition-all peer-checked:bg-content-secondary peer-checked:pl-6`}>
        <div className="!size-[18px] rounded-full bg-[white] shadow-[0px_2px_4px_rgba(0,_35,_11,_0.20)]" />
      </div>
    </label>
  );
};
