export const DONATIONS_SIZE = 100;

export const NATIVE_ADD_POSITION = 9;

export const LIST_ITEM_ADD_POSITION = 15;

export const TEMPORARY_LIST_ITEM_ADD_POSITION = 8;

export const LOC_SIZE = 25;

export const CONVERSATION_SIZE = 1000;
