import React from 'react';

import type { IconName } from '@/core/components/Icons';
import Icons from '@/core/components/Icons';
import type { AbstractRoute } from '@/core/lib/router/route';
import { useRouterContext } from '@/core/lib/router/router.context';

export type ItemType = 'button' | 'link';

export interface ScrollDownItemProps {
  icon: IconName;
  content: string;
  href?: AbstractRoute;
  disabled?: boolean;
  type: ItemType;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
const ScrollDownItem: React.FC<ScrollDownItemProps> = ({ type, href, icon, content, disabled, onClick }) => {
  const { Link } = useRouterContext();

  const onClickItem: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    if (onClick) onClick(e);
  };

  return (
    <>
      {type === 'link' &&
        (!href ? (
          <button type="button" className="flex w-full items-center gap-2 rounded-2 bg-bg-primary px-2 py-3 md:py-4" disabled>
            <span className="opacity-60">
              <Icons icon={icon} size="20" color="placeholder" />
            </span>
            <span className="text-body-secondary text-left text-content-disable">{content}</span>
          </button>
        ) : (
          <Link href={href} className={`flex w-full items-center gap-2 rounded-2 bg-bg-primary px-2 py-3 text-left md:py-4 ${disabled ? 'pointer-events-none cursor-default' : 'hover:bg-bg-pale'}`}>
            <span className={`${disabled ? 'opacity-60' : ''}`}>
              <Icons icon={icon} size="20" color="placeholder" />
            </span>
            <p className={`text-body-secondary ${disabled ? 'text-content-disable' : 'text-content-primary'}`}>{content}</p>
          </Link>
        ))}
      {type === 'button' && (
        <button
          type="button"
          className={`flex w-full items-center gap-2 rounded-2 bg-bg-primary px-2 py-3 text-left ${disabled ? '' : 'hover:bg-bg-pale'} md:py-4`}
          onClick={onClickItem}
          disabled={disabled}
        >
          <span className={`${disabled ? 'opacity-60' : ''}`}>
            <Icons icon={icon} size="20" color="placeholder" />
          </span>
          <span className={`text-body-secondary ${disabled ? 'text-content-disable' : 'text-content-primary'}`}>{content}</span>
        </button>
      )}
    </>
  );
};

export default ScrollDownItem;
