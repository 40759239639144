import React from 'react';

import Modal from '@/core/components/Modal';
import CguControl from '@/core/components/UserControl/controls/CguControl';
import DeleteControl from '@/core/components/UserControl/controls/DeleteControl';
import EmailCheckControl from '@/core/components/UserControl/controls/EmailCheckControl';
import EmailErrorControl from '@/core/components/UserControl/controls/EmailErrorControl';
import NotificationControl from '@/core/components/UserControl/controls/NotificationControl';
import RsControl from '@/core/components/UserControl/controls/RsControl';
import UsernameControl from '@/core/components/UserControl/controls/UsernameControl';
import Store from '@/core/lib/new-architecture/store';
import type { UserControlResult } from '@/core/types/users';
import { UserControlType } from '@/core/types/users';

const controlSwitch = (control: NonNullable<UserControlResult>): React.FC | null => {
  switch (control.type) {
    case UserControlType.CGU:
      return CguControl;
    case UserControlType.DELETE:
      return DeleteControl;
    case UserControlType.EMAIL_CHECK:
      return EmailCheckControl;
    case UserControlType.EMAIL_ERROR:
      return EmailErrorControl;
    case UserControlType.RS:
      return RsControl;
    case UserControlType.USERNAME:
      return UsernameControl;
    case UserControlType.NOTIFICATIONS:
      return NotificationControl;
    default:
      return null;
  }
};

const UserControl: React.FC = () => {
  const { data: current } = Store.current.useCurrent();

  if (!current || !current.control) return null;

  const Comp = controlSwitch(current.control);

  if (!Comp) return null;

  return (
    <Modal isOpen onClose={() => {}} closeOnEscape={false} type={current.control?.type === UserControlType.NOTIFICATIONS ? 'popup' : 'tunnel'}>
      <div className="h-full pb-safe-bottom pt-safe-top">
        <Comp />
      </div>
    </Modal>
  );
};

export default UserControl;
