import * as yup from 'yup';

import { NOT_URL_REGEX, OTP_REGEX, USERNAME_AT_LEAST_2_REGEX, USERNAME_REGEX } from '@/core/lib/regex';

export const usersOtpFormSchema = yup.object({
  otp: yup.string().matches(OTP_REGEX).required(),
});

export type UsersOtpForm = yup.InferType<typeof usersOtpFormSchema>;

export const userResultSchema = yup.object({
  uuid: yup.string().required(),
  username: yup.string().required(),
  state: yup.string().required(),
  avatar_url: yup.string().required(),
  donation_donated: yup.number().required(),
  donation_received: yup.number().required(),
  is_nice: yup.boolean().required(),
  is_punctual: yup.boolean().required(),
  co2_given: yup.number().required(),
  co2_received: yup.number().required(),
  created_at: yup.string().required(),
  city: yup.number().nullable(),
  sub: yup.number().nullable(),
});

export type UserResult = yup.InferType<typeof userResultSchema>;

export const usersResultSchema = yup.object({
  users: yup.array(userResultSchema.required()).nullable(),
});

export type UsersResult = yup.InferType<typeof usersResultSchema>;

export const usersUsersResultSchema = yup.object({
  users: yup.array(userResultSchema.required()).required(),
});

export type UsersUsersResult = yup.InferType<typeof usersUsersResultSchema>;

export enum UserControlType {
  CGU = 'cgu',
  DELETE = 'delete',
  EMAIL_CHECK = 'email_check',
  EMAIL_ERROR = 'email_error',
  RS = 'rs',
  USERNAME = 'username',
  NOTIFICATIONS = 'notifications',
}

export const userControlResultSchema = yup
  .object({
    type: yup.mixed<UserControlType>().oneOf(Object.values(UserControlType)).required(),
    data: yup.object({}).optional(),
    created_at: yup.string().required(),
  })
  .nullable();

export type UserControlResult = yup.InferType<typeof userControlResultSchema>;

export const userControlCguFormSchema = yup
  .object({
    cgu: yup.boolean().required('control.cgu.required').isTrue('control.cgu.required'),
  })
  .required();

export type UserControlCguForm = yup.InferType<typeof userControlCguFormSchema>;

export const userControlEmailCheckFormSchema = yup
  .object({
    otp: yup.string().matches(OTP_REGEX, 'control.email-check.6-digits').required('control.email-check.required'),
  })
  .required();

export type UserControlEmailCheckForm = yup.InferType<typeof userControlEmailCheckFormSchema>;

export const userControlRsFormSchema = yup
  .object({
    username: yup
      .string()
      .matches(USERNAME_AT_LEAST_2_REGEX, 'errors.inputs.username')
      .matches(USERNAME_REGEX, 'control.rs.username.required')
      .matches(NOT_URL_REGEX, 'errors.inputs.has_not_url')
      .required('control.rs.username.required'),
    loc: yup.string().required('control.rs.loc.required'),
  })
  .required();

export type UserControlRsForm = yup.InferType<typeof userControlRsFormSchema>;

export const userControlUsernameFormSchema = yup
  .object({
    username: yup
      .string()
      .matches(USERNAME_AT_LEAST_2_REGEX, 'errors.inputs.username')
      .matches(USERNAME_REGEX, 'control.username.required')
      .matches(NOT_URL_REGEX, 'errors.inputs.has_not_url')
      .required('control.username.required'),
  })
  .required();

export type UserControlUsernameForm = yup.InferType<typeof userControlUsernameFormSchema>;
