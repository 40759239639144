import { parse } from 'regexparam';
import type { InferType } from 'yup';
import * as yup from 'yup';

import makeClone from '@/core/lib/makeClone';
import type { RouterQuery } from '@/core/lib/router/router.context';
import { useRouterContext } from '@/core/lib/router/router.context';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyYupSchema = yup.Schema<any, any>;

export interface AnySearchParams {
  [key: string]: string | number | string[] | number[] | boolean | undefined | null;
}

export enum YieldLoveExperiment {
  REMOVE_LAZY_LOAD = 'REMOVE_LAZY_LOAD',
  ONLY_USE_DIV_ID = 'ONLY_USE_DIV_ID',
  NATIVE = 'NATIVE',
}

// here we defined app wide possible search params
const commonSearchParamsSchema = yup.object({
  openAuth: yup.boolean().optional(),
  openGive: yup.boolean().optional(),
  redirectTo: yup.string().optional(),
  utm_source: yup.string().optional(),
  utm_medium: yup.string().optional(),
  utm_campaign: yup.string().optional(),
  adsense: yup
    .number()
    .transform(value => (Number.isNaN(value) ? undefined : value))
    .optional(),
  yieldlove: yup.mixed<YieldLoveExperiment>().oneOf(Object.values(YieldLoveExperiment)).optional(),
});

export type CommonSearchParams = InferType<typeof commonSearchParamsSchema>;

export const empty = yup.object({});
export type Empty = typeof empty;

export interface UseParamsReturn<Page extends AnyYupSchema = Empty, Search extends AnyYupSchema = Empty> {
  page: InferType<Page>;
  search: InferType<Search>;
  common: CommonSearchParams;
}

interface UseCommonParamsReturn {
  query: RouterQuery;
  common: CommonSearchParams;
}

export abstract class AbstractRoute {
  public common: CommonSearchParams;

  public isExternal = false;

  constructor(common: CommonSearchParams = {}) {
    this.common = common;
  }

  computeParams(params: AnySearchParams = {}): string {
    const search = new URLSearchParams();

    Object.entries({ ...this.common, ...params }).forEach(([key, value]) => {
      if (value || typeof value === 'number') {
        if (Array.isArray(value)) {
          value.forEach(item => {
            search.append(`${key}[]`, String(item));
          });
        } else {
          search.set(key, String(value));
        }
      }
    });

    if (Array.from(search.entries()).length > 0) {
      return `?${search.toString()}`;
    }

    return '';
  }

  updateCommon(cbOrValue: (prev: CommonSearchParams) => CommonSearchParams | CommonSearchParams) {
    let newCommon = makeClone(this.common);

    if (typeof cbOrValue === 'function') {
      newCommon = cbOrValue(newCommon);
    } else {
      newCommon = cbOrValue;
    }

    this.common = newCommon;
  }

  static searchParamsToCommon(searchParams: URLSearchParams): CommonSearchParams {
    const obj = Array.from(searchParams.entries()).reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});
    const common = commonSearchParamsSchema.cast(obj, { stripUnknown: true, assert: false });
    return common;
  }

  static test(url: string): boolean {
    return parse(this.path).pattern.test(url);
  }

  static exec(path: string) {
    const parsed = parse(this.path);
    const matches = parsed.pattern.exec(path);

    if (!matches) return {};

    const out = parsed.keys.reduce((acc, key, index) => ({ ...acc, [key]: matches[index + 1] ?? null }), {});

    return out;
  }

  static useCommonParams(): UseCommonParamsReturn {
    const { query } = useRouterContext();

    const queryWithArray = Object.entries(query ?? {}).reduce((acc, [key, value]) => {
      if (key.includes('[]')) {
        const keyReplaced = key.replace('[]', '');
        return { ...acc, [keyReplaced]: Array.isArray(value) ? value : [value] };
      }

      return { ...acc, [key]: value };
    }, {});

    const common = commonSearchParamsSchema.cast(queryWithArray, { stripUnknown: true, assert: false });

    return { common, query: queryWithArray };
  }

  static path: string;

  static isPrivate: boolean;

  abstract resolve(): string;
  abstract isPrivate(): boolean;
  abstract getPath(): string;
  abstract clone(common?: CommonSearchParams): AbstractRoute;

  static useParams(): UseParamsReturn {
    throw new Error('Should be implemented');
  }

  static init(_url: URL): AbstractRoute {
    throw new Error('Should be implemented');
  }
}
